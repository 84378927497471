







































































































import { userModule } from "@/store/modules/user";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "DynamicSalary",
  setup() {
    const hourlyBonusSeller: { [key: number]: number } = {
      0: 0,
      100: 5,
      200: 10,
      300: 15,
      400: 20,
      500: 30,
      600: 40,
    };

    const hoursSold = computed(() => Math.floor(userModule.state.profile.minutesSold / 60));
    const hoursSoldFlooredToClosest100 = computed(() => hoursSold.value > 600 ? 600 : Math.floor(hoursSold.value / 100) * 100);
    const newHourlySellerBonus = computed(() => hourlyBonusSeller[hoursSoldFlooredToClosest100.value]);

    const progressBarValue = computed(() => {
      if (userModule.state.profile.minutesSold) {
        return hoursSold.value / 6;
      }
      return 2;
    });

    return {
      hoursSold,
      newHourlySellerBonus,
      progressBarValue,
    };
  },
});
