




























import { computed, defineComponent, inject, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

import { SHOW_INVOICE } from "@/views/seller/Payouts.vue";
import { createReadablePayout } from "@/components/payouts/helpers";

export default defineComponent({
  name: "PayoutsTableRow",
  props: {
    payout: {
      type: Object as PropType<Learnlink.Payout.Payout>,
      default: () => null,
    },
  },
  setup: (props: { payout: Learnlink.Payout.Payout }) => {
    const showPayout = inject<(payout: Learnlink.Payout.Payout | null) => void>(SHOW_INVOICE);

    const readablePayout = computed(() => createReadablePayout(props.payout));

    return { showPayout, readablePayout };
  },
});
