import { formatUnixTimestamp } from "@/helpers/timeUtils";
import Learnlink from "@learnlink/interfaces";

enum ReadableStatus {
  paid = "Utbetalt",
  in_transit = "På vei",
  failed = "Feilet"
}
export const mapStatus = (status: keyof typeof ReadableStatus): string => {
  return ReadableStatus[status];
};

export const createReadablePayout = (payout: Learnlink.Payout.Payout): Learnlink.Payout.Readable => {
  const format = "Do MMM YYYY";

  return {
    ...payout,
    amount: Number(payout.amount).toLocaleString("no"),
    created: formatUnixTimestamp(payout.created, format),
    paid: formatUnixTimestamp(payout.paid, format),
    status: mapStatus(payout.status),
  };
};
